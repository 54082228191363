<template>
  <div class="schedule">

    <div class="session" style="grid-area: time-1530 / track-3-start / time-1700 / track-4-end;">
      <span class="badge badge-secondary">15:30 - 17:00</span>
      <div class="session-title ">
        <span class="sessionname">AATS PG - Adult Cardiac 3</span>
      </div>
    </div>
    
    <div class="session" style="grid-area: time-1330 / track-3-start / time-1500 / track-4-end;">
      <span class="badge badge-secondary">13:30 - 15:00</span>
      <div class="session-title ">
        <span class="sessionname">AATS PG - Adult Cardiac 2</span>
      </div>
    </div>
    
    <div class="session" style="grid-area: time-1000 / track-3-start / time-1200 / track-4-end;">
      <span class="badge badge-secondary">10:00 - 12:00</span>
      <div class="session-title ">
        <span class="sessionname">AATS PG - Adult Cardiac 1</span>
      </div>
    </div>
    
    <div class="session" style="grid-area: time-1330 / track-5-start / time-1500 / track-6-end;">
      <span class="badge badge-secondary">13:30 - 15:00</span>
      <div class="session-title ">
        <span class="sessionname">AATS PG - Pediatric 2</span>
      </div>
    </div>
    
    <div class="session" style="grid-area: time-1000 / track-5-start / time-1200 / track-6-end;">
      <span class="badge badge-secondary">10:00 - 12:00</span>
      <div class="session-title ">
        <span class="sessionname">AATS PG - Pediatric 1</span>
      </div>
    </div>
    
    <div class="session" style="grid-area: time-1530 / track-5-start / time-1700 / track-6-end;">
      <span class="badge badge-secondary">15:30 - 17:00</span>
      <div class="session-title ">
        <span class="sessionname">AATS PG - Pediatric 3</span>
      </div>
    </div>
    
    <div class="session" style="grid-area: time-1000 / track-7 / time-1100 / track-7;">
      <span class="badge badge-secondary">10:00 - 11:00</span>
      <div class="session-title ">
        <span class="sessionname">AATS PG - Thoracic 1</span>
      </div>
    </div>
    
    <div class="session" style="grid-area: time-1100 / track-7 / time-1200 / track-7;">
      <span class="badge badge-secondary">11:00 - 12:00</span>
      <div class="session-title ">
        <span class="sessionname">AATS PG - Thoracic 2</span>
      </div>
    </div>
    
    <div class="session" style="grid-area: time-1330 / track-7 / time-1500 / track-7;">
      <span class="badge badge-secondary">13:30 - 15:00</span>
      <div class="session-title ">
        <span class="sessionname">AATS PG - Thoracic 3</span>
      </div>
    </div>
    
    <div class="session"  style="grid-area: time-1530 / track-7 / time-1700 / track-7;">
      <span class="badge badge-secondary">15:30 - 17:00</span>
      <div class="session-title ">
        <span class="sessionname">AATS PG - Thoracic 4</span>
      </div>
    </div>

    <div class="track-slot" style="grid-row: tracks;text-align:center;font-size:13px;">Floor</div>
    <div class="track-slot" aria-hidden="true" style="grid-column: track-1-start / track-4-end; grid-row: tracks;">1F</div>
    <div class="track-slot" aria-hidden="true" style="grid-column: track-5-start / track-7-end; grid-row: tracks;">2F</div>
    <div class="track-slot" aria-hidden="true" style="grid-column: track-8-start / track-10-end; grid-row: tracks;">Annex</div>

    <div class="track-slot" style="grid-row: tracks2;text-align:center;font-size:13px;">Venue</div>
    <div class="track-slot" aria-hidden="true" style="grid-column: track-1-start / track-2-end; grid-row: tracks2;">Session Room 1</div>
    <div class="track-slot" aria-hidden="true" style="grid-column: track-3-start / track-4-end; grid-row: tracks2;">Session Room 2</div>
    <div class="track-slot" aria-hidden="true" style="grid-column: track-5-start / track-6-end; grid-row: tracks2;">Session Room 3</div>
    <div class="track-slot" aria-hidden="true" style="grid-column: track-7; grid-row: tracks2;">Session Room 4</div>
    <div class="track-slot" aria-hidden="true" style="grid-column: track-8; grid-row: tracks2;">Session Room 5</div>
    <div class="track-slot" aria-hidden="true" style="grid-column: track-9; grid-row: tracks2;">Session Room 6</div>
    <div class="track-slot" aria-hidden="true" style="grid-column: track-10; grid-row: tracks2;">Session Room 7</div>

    <div class="track-slot" style="grid-row: tracks3;text-align:center;font-size:13px;">Room No.</div>
    <div class="track-slot" aria-hidden="true" style="grid-column: track-1; grid-row: tracks3;">101-103 (A)</div>
    <div class="track-slot" aria-hidden="true" style="grid-column: track-2; grid-row: tracks3;">106-108 (B)</div>
    <div class="track-slot" aria-hidden="true" style="grid-column: track-3; grid-row: tracks3;">104-105 (A)</div>
    <div class="track-slot" aria-hidden="true" style="grid-column: track-4; grid-row: tracks3;">109-110 (B)</div>
    <div class="track-slot" aria-hidden="true" style="grid-column: track-5; grid-row: tracks3;">201-202 (A)</div>
    <div class="track-slot" aria-hidden="true" style="grid-column: track-6; grid-row: tracks3;">203-204 (B)</div>
    <div class="track-slot" aria-hidden="true" style="grid-column: track-7; grid-row: tracks3;">APEC Hall</div>
    <div class="track-slot" aria-hidden="true" style="grid-column: track-8; grid-row: tracks3;">Auditorium</div>
    <div class="track-slot" aria-hidden="true" style="grid-column: track-9; grid-row: tracks3;">Aux1</div>
    <div class="track-slot" aria-hidden="true" style="grid-column: track-10; grid-row: tracks3;">Aux2</div>
    
    <span class="time-slot " style="grid-row: time-0700;"><span class="hhmm"></span></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-0700; grid-column: track-1;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-0700; grid-column: track-2;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-0700; grid-column: track-3;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-0700; grid-column: track-4;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-0700; grid-column: track-5;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-0700; grid-column: track-6;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-0700; grid-column: track-7;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-0700; grid-column: track-8;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-0700; grid-column: track-9;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-0700; grid-column: track-10;"></span>

    <span class="time-slot " style="grid-row: time-0715;"><span class="hhmm"></span></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-0715; grid-column: track-1;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-0715; grid-column: track-2;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-0715; grid-column: track-3;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-0715; grid-column: track-4;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-0715; grid-column: track-5;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-0715; grid-column: track-6;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-0715; grid-column: track-7;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-0715; grid-column: track-8;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-0715; grid-column: track-9;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-0715; grid-column: track-10;"></span>
    <span class="time-slot " style="grid-row: time-0730;"><span class="hhmm">07:30</span></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-0730; grid-column: track-1;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-0730; grid-column: track-2;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-0730; grid-column: track-3;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-0730; grid-column: track-4;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-0730; grid-column: track-5;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-0730; grid-column: track-6;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-0730; grid-column: track-7;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-0730; grid-column: track-8;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-0730; grid-column: track-9;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-0730; grid-column: track-10;"></span>

    <span class="time-slot " style="grid-row: time-0745;"><span class="hhmm"></span></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-0745; grid-column: track-1;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-0745; grid-column: track-2;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-0745; grid-column: track-3;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-0745; grid-column: track-4;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-0745; grid-column: track-5;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-0745; grid-column: track-6;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-0745; grid-column: track-7;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-0745; grid-column: track-8;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-0745; grid-column: track-9;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-0745; grid-column: track-10;"></span>

    <span class="time-slot " style="grid-row: time-0800;"><span class="hhmm">08:00</span></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-0800; grid-column: track-1;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-0800; grid-column: track-2;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-0800; grid-column: track-3;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-0800; grid-column: track-4;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-0800; grid-column: track-5;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-0800; grid-column: track-6;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-0800; grid-column: track-7;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-0800; grid-column: track-8;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-0800; grid-column: track-9;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-0800; grid-column: track-10;"></span>

    <span class="time-slot " style="grid-row: time-0815;"><span class="hhmm"></span></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-0815; grid-column: track-1;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-0815; grid-column: track-2;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-0815; grid-column: track-3;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-0815; grid-column: track-4;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-0815; grid-column: track-5;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-0815; grid-column: track-6;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-0815; grid-column: track-7;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-0815; grid-column: track-8;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-0815; grid-column: track-9;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-0815; grid-column: track-10;"></span>

    <span class="time-slot " style="grid-row: time-0830;"><span class="hhmm">08:30</span></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-0830; grid-column: track-1;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-0830; grid-column: track-2;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-0830; grid-column: track-3;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-0830; grid-column: track-4;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-0830; grid-column: track-5;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-0830; grid-column: track-6;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-0830; grid-column: track-7;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-0830; grid-column: track-8;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-0830; grid-column: track-9;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-0830; grid-column: track-10;"></span>

    <span class="time-slot " style="grid-row: time-0845;"><span class="hhmm"></span></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-0845; grid-column: track-1;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-0845; grid-column: track-2;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-0845; grid-column: track-3;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-0845; grid-column: track-4;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-0845; grid-column: track-5;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-0845; grid-column: track-6;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-0845; grid-column: track-7;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-0845; grid-column: track-8;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-0845; grid-column: track-9;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-0845; grid-column: track-10;"></span>

    <span class="time-slot " style="grid-row: time-0900;"><span class="hhmm">09:00</span></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-0900; grid-column: track-1;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-0900; grid-column: track-2;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-0900; grid-column: track-3;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-0900; grid-column: track-4;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-0900; grid-column: track-5;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-0900; grid-column: track-6;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-0900; grid-column: track-7;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-0900; grid-column: track-8;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-0900; grid-column: track-9;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-0900; grid-column: track-10;"></span>

    <span class="time-slot " style="grid-row: time-0915;"><span class="hhmm"></span></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-0915; grid-column: track-1;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-0915; grid-column: track-2;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-0915; grid-column: track-3;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-0915; grid-column: track-4;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-0915; grid-column: track-5;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-0915; grid-column: track-6;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-0915; grid-column: track-7;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-0915; grid-column: track-8;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-0915; grid-column: track-9;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-0915; grid-column: track-10;"></span>

    <span class="time-slot " style="grid-row: time-0930;"><span class="hhmm">09:30</span></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-0930; grid-column: track-1;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-0930; grid-column: track-2;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-0930; grid-column: track-3;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-0930; grid-column: track-4;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-0930; grid-column: track-5;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-0930; grid-column: track-6;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-0930; grid-column: track-7;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-0930; grid-column: track-8;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-0930; grid-column: track-9;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-0930; grid-column: track-10;"></span>

    <span class="time-slot " style="grid-row: time-0945;"><span class="hhmm"></span></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-0945; grid-column: track-1;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-0945; grid-column: track-2;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-0945; grid-column: track-3;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-0945; grid-column: track-4;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-0945; grid-column: track-5;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-0945; grid-column: track-6;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-0945; grid-column: track-7;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-0945; grid-column: track-8;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-0945; grid-column: track-9;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-0945; grid-column: track-10;"></span>

    <span class="time-slot " style="grid-row: time-1000;"><span class="hhmm">10:00</span></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1000; grid-column: track-1;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1000; grid-column: track-2;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1000; grid-column: track-3;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1000; grid-column: track-4;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1000; grid-column: track-5;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1000; grid-column: track-6;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1000; grid-column: track-7;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1000; grid-column: track-8;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1000; grid-column: track-9;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1000; grid-column: track-10;"></span>

    <span class="time-slot " style="grid-row: time-1015;"><span class="hhmm"></span></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1015; grid-column: track-1;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1015; grid-column: track-2;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1015; grid-column: track-3;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1015; grid-column: track-4;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1015; grid-column: track-5;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1015; grid-column: track-6;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1015; grid-column: track-7;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1015; grid-column: track-8;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1015; grid-column: track-9;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1015; grid-column: track-10;"></span>

    <span class="time-slot " style="grid-row: time-1030;"><span class="hhmm">10:30</span></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1030; grid-column: track-1;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1030; grid-column: track-2;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1030; grid-column: track-3;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1030; grid-column: track-4;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1030; grid-column: track-5;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1030; grid-column: track-6;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1030; grid-column: track-7;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1030; grid-column: track-8;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1030; grid-column: track-9;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1030; grid-column: track-10;"></span>

    <span class="time-slot " style="grid-row: time-1045;"><span class="hhmm"></span></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1045; grid-column: track-1;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1045; grid-column: track-2;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1045; grid-column: track-3;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1045; grid-column: track-4;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1045; grid-column: track-5;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1045; grid-column: track-6;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1045; grid-column: track-7;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1045; grid-column: track-8;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1045; grid-column: track-9;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1045; grid-column: track-10;"></span>

    <span class="time-slot " style="grid-row: time-1100;"><span class="hhmm">11:00</span></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1100; grid-column: track-1;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1100; grid-column: track-2;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1100; grid-column: track-3;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1100; grid-column: track-4;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1100; grid-column: track-5;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1100; grid-column: track-6;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1100; grid-column: track-7;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1100; grid-column: track-8;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1100; grid-column: track-9;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1100; grid-column: track-10;"></span>

    <span class="time-slot " style="grid-row: time-1115;"><span class="hhmm"></span></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1115; grid-column: track-1;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1115; grid-column: track-2;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1115; grid-column: track-3;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1115; grid-column: track-4;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1115; grid-column: track-5;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1115; grid-column: track-6;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1115; grid-column: track-7;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1115; grid-column: track-8;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1115; grid-column: track-9;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1115; grid-column: track-10;"></span>

    <span class="time-slot " style="grid-row: time-1130;"><span class="hhmm">11:30</span></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1130; grid-column: track-1;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1130; grid-column: track-2;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1130; grid-column: track-3;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1130; grid-column: track-4;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1130; grid-column: track-5;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1130; grid-column: track-6;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1130; grid-column: track-7;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1130; grid-column: track-8;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1130; grid-column: track-9;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1130; grid-column: track-10;"></span>

    <span class="time-slot " style="grid-row: time-1145;"><span class="hhmm"></span></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1145; grid-column: track-1;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1145; grid-column: track-2;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1145; grid-column: track-3;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1145; grid-column: track-4;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1145; grid-column: track-5;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1145; grid-column: track-6;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1145; grid-column: track-7;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1145; grid-column: track-8;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1145; grid-column: track-9;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1145; grid-column: track-10;"></span>

    <span class="time-slot " style="grid-row: time-1200;"><span class="hhmm">12:00</span></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1200; grid-column: track-1;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1200; grid-column: track-2;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1200; grid-column: track-3;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1200; grid-column: track-4;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1200; grid-column: track-5;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1200; grid-column: track-6;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1200; grid-column: track-7;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1200; grid-column: track-8;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1200; grid-column: track-9;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1200; grid-column: track-10;"></span>

    <span class="time-slot " style="grid-row: time-1215;"><span class="hhmm"></span></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1215; grid-column: track-1;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1215; grid-column: track-2;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1215; grid-column: track-3;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1215; grid-column: track-4;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1215; grid-column: track-5;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1215; grid-column: track-6;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1215; grid-column: track-7;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1215; grid-column: track-8;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1215; grid-column: track-9;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1215; grid-column: track-10;"></span>

    <span class="time-slot " style="grid-row: time-1225;"><span class="hhmm">12:25</span></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1225; grid-column: track-1;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1225; grid-column: track-2;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1225; grid-column: track-3;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1225; grid-column: track-4;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1225; grid-column: track-5;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1225; grid-column: track-6;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1225; grid-column: track-7;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1225; grid-column: track-8;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1225; grid-column: track-9;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1225; grid-column: track-10;"></span>                      
    
    <span class="time-slot " style="grid-row: time-1230;"><span class="hhmm">12:30</span></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1230; grid-column: track-1;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1230; grid-column: track-2;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1230; grid-column: track-3;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1230; grid-column: track-4;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1230; grid-column: track-5;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1230; grid-column: track-6;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1230; grid-column: track-7;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1230; grid-column: track-8;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1230; grid-column: track-9;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1230; grid-column: track-10;"></span>

    <span class="time-slot " style="grid-row: time-1245;"><span class="hhmm"></span></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1245; grid-column: track-1;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1245; grid-column: track-2;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1245; grid-column: track-3;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1245; grid-column: track-4;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1245; grid-column: track-5;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1245; grid-column: track-6;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1245; grid-column: track-7;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1245; grid-column: track-8;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1245; grid-column: track-9;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1245; grid-column: track-10;"></span>

    <span class="time-slot " style="grid-row: time-1300;"><span class="hhmm">13:00</span></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1300; grid-column: track-1;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1300; grid-column: track-2;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1300; grid-column: track-3;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1300; grid-column: track-4;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1300; grid-column: track-5;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1300; grid-column: track-6;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1300; grid-column: track-7;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1300; grid-column: track-8;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1300; grid-column: track-9;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1300; grid-column: track-10;"></span>

    <span class="time-slot " style="grid-row: time-1315;"><span class="hhmm"></span></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1315; grid-column: track-1;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1315; grid-column: track-2;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1315; grid-column: track-3;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1315; grid-column: track-4;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1315; grid-column: track-5;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1315; grid-column: track-6;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1315; grid-column: track-7;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1315; grid-column: track-8;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1315; grid-column: track-9;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1315; grid-column: track-10;"></span>

    <span class="time-slot " style="grid-row: time-1330;"><span class="hhmm">13:30</span></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1330; grid-column: track-1;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1330; grid-column: track-2;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1330; grid-column: track-3;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1330; grid-column: track-4;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1330; grid-column: track-5;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1330; grid-column: track-6;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1330; grid-column: track-7;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1330; grid-column: track-8;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1330; grid-column: track-9;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1330; grid-column: track-10;"></span>

    <span class="time-slot " style="grid-row: time-1345;"><span class="hhmm"></span></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1345; grid-column: track-1;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1345; grid-column: track-2;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1345; grid-column: track-3;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1345; grid-column: track-4;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1345; grid-column: track-5;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1345; grid-column: track-6;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1345; grid-column: track-7;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1345; grid-column: track-8;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1345; grid-column: track-9;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1345; grid-column: track-10;"></span>

    <span class="time-slot " style="grid-row: time-1400;"><span class="hhmm">14:00</span></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1400; grid-column: track-1;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1400; grid-column: track-2;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1400; grid-column: track-3;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1400; grid-column: track-4;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1400; grid-column: track-5;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1400; grid-column: track-6;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1400; grid-column: track-7;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1400; grid-column: track-8;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1400; grid-column: track-9;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1400; grid-column: track-10;"></span>

    <span class="time-slot " style="grid-row: time-1415;"><span class="hhmm"></span></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1415; grid-column: track-1;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1415; grid-column: track-2;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1415; grid-column: track-3;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1415; grid-column: track-4;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1415; grid-column: track-5;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1415; grid-column: track-6;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1415; grid-column: track-7;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1415; grid-column: track-8;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1415; grid-column: track-9;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1415; grid-column: track-10;"></span>

    <span class="time-slot " style="grid-row: time-1430;"><span class="hhmm">14:30</span></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1430; grid-column: track-1;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1430; grid-column: track-2;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1430; grid-column: track-3;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1430; grid-column: track-4;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1430; grid-column: track-5;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1430; grid-column: track-6;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1430; grid-column: track-7;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1430; grid-column: track-8;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1430; grid-column: track-9;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1430; grid-column: track-10;"></span>

    <span class="time-slot " style="grid-row: time-1445;"><span class="hhmm"></span></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1445; grid-column: track-1;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1445; grid-column: track-2;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1445; grid-column: track-3;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1445; grid-column: track-4;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1445; grid-column: track-5;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1445; grid-column: track-6;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1445; grid-column: track-7;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1445; grid-column: track-8;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1445; grid-column: track-9;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1445; grid-column: track-10;"></span>

    <span class="time-slot " style="grid-row: time-1500;"><span class="hhmm">15:00</span></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1500; grid-column: track-1;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1500; grid-column: track-2;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1500; grid-column: track-3;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1500; grid-column: track-4;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1500; grid-column: track-5;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1500; grid-column: track-6;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1500; grid-column: track-7;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1500; grid-column: track-8;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1500; grid-column: track-9;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1500; grid-column: track-10;"></span>

    <span class="time-slot " style="grid-row: time-1515;"><span class="hhmm"></span></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1515; grid-column: track-1;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1515; grid-column: track-2;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1515; grid-column: track-3;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1515; grid-column: track-4;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1515; grid-column: track-5;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1515; grid-column: track-6;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1515; grid-column: track-7;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1515; grid-column: track-8;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1515; grid-column: track-9;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1515; grid-column: track-10;"></span>

    <span class="time-slot " style="grid-row: time-1530;"><span class="hhmm">15:30</span></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1530; grid-column: track-1;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1530; grid-column: track-2;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1530; grid-column: track-3;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1530; grid-column: track-4;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1530; grid-column: track-5;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1530; grid-column: track-6;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1530; grid-column: track-7;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1530; grid-column: track-8;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1530; grid-column: track-9;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1530; grid-column: track-10;"></span>

    <span class="time-slot " style="grid-row: time-1545;"><span class="hhmm"></span></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1545; grid-column: track-1;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1545; grid-column: track-2;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1545; grid-column: track-3;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1545; grid-column: track-4;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1545; grid-column: track-5;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1545; grid-column: track-6;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1545; grid-column: track-7;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1545; grid-column: track-8;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1545; grid-column: track-9;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1545; grid-column: track-10;"></span>

    <span class="time-slot " style="grid-row: time-1600;"><span class="hhmm">16:00</span></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1600; grid-column: track-1;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1600; grid-column: track-2;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1600; grid-column: track-3;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1600; grid-column: track-4;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1600; grid-column: track-5;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1600; grid-column: track-6;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1600; grid-column: track-7;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1600; grid-column: track-8;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1600; grid-column: track-9;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1600; grid-column: track-10;"></span>

    <span class="time-slot " style="grid-row: time-1615;"><span class="hhmm"></span></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1615; grid-column: track-1;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1615; grid-column: track-2;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1615; grid-column: track-3;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1615; grid-column: track-4;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1615; grid-column: track-5;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1615; grid-column: track-6;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1615; grid-column: track-7;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1615; grid-column: track-8;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1615; grid-column: track-9;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1615; grid-column: track-10;"></span>

    <span class="time-slot " style="grid-row: time-1630;"><span class="hhmm">16:30</span></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1630; grid-column: track-1;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1630; grid-column: track-2;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1630; grid-column: track-3;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1630; grid-column: track-4;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1630; grid-column: track-5;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1630; grid-column: track-6;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1630; grid-column: track-7;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1630; grid-column: track-8;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1630; grid-column: track-9;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1630; grid-column: track-10;"></span>

    <span class="time-slot " style="grid-row: time-1645;"><span class="hhmm"></span></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1645; grid-column: track-1;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1645; grid-column: track-2;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1645; grid-column: track-3;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1645; grid-column: track-4;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1645; grid-column: track-5;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1645; grid-column: track-6;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1645; grid-column: track-7;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1645; grid-column: track-8;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1645; grid-column: track-9;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1645; grid-column: track-10;"></span>

    <span class="time-slot " style="grid-row: time-1700;"><span class="hhmm">17:00</span></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1700; grid-column: track-1;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1700; grid-column: track-2;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1700; grid-column: track-3;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1700; grid-column: track-4;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1700; grid-column: track-5;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1700; grid-column: track-6;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1700; grid-column: track-7;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1700; grid-column: track-8;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1700; grid-column: track-9;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1700; grid-column: track-10;"></span>

    <span class="time-slot " style="grid-row: time-1715;"><span class="hhmm"></span></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1715; grid-column: track-1;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1715; grid-column: track-2;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1715; grid-column: track-3;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1715; grid-column: track-4;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1715; grid-column: track-5;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1715; grid-column: track-6;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1715; grid-column: track-7;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1715; grid-column: track-8;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1715; grid-column: track-9;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1715; grid-column: track-10;"></span>

    <span class="time-slot " style="grid-row: time-1730;"><span class="hhmm">17:30</span></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1730; grid-column: track-1;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1730; grid-column: track-2;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1730; grid-column: track-3;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1730; grid-column: track-4;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1730; grid-column: track-5;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1730; grid-column: track-6;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1730; grid-column: track-7;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1730; grid-column: track-8;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1730; grid-column: track-9;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1730; grid-column: track-10;"></span>

    <span class="time-slot " style="grid-row: time-1745;"><span class="hhmm"></span></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1745; grid-column: track-1;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1745; grid-column: track-2;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1745; grid-column: track-3;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1745; grid-column: track-4;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1745; grid-column: track-5;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1745; grid-column: track-6;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1745; grid-column: track-7;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1745; grid-column: track-8;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1745; grid-column: track-9;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1745; grid-column: track-10;"></span>

    <span class="time-slot " style="grid-row: time-1800;"><span class="hhmm">18:00</span></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1800; grid-column: track-1;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1800; grid-column: track-2;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1800; grid-column: track-3;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1800; grid-column: track-4;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1800; grid-column: track-5;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1800; grid-column: track-6;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1800; grid-column: track-7;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1800; grid-column: track-8;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1800; grid-column: track-9;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1800; grid-column: track-10;"></span>

    <span class="time-slot " style="grid-row: time-1815;"><span class="hhmm"></span></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1815; grid-column: track-1;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1815; grid-column: track-2;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1815; grid-column: track-3;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1815; grid-column: track-4;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1815; grid-column: track-5;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1815; grid-column: track-6;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1815; grid-column: track-7;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1815; grid-column: track-8;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1815; grid-column: track-9;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1815; grid-column: track-10;"></span>

    <span class="time-slot " style="grid-row: time-1830;"><span class="hhmm">18:30</span></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1830; grid-column: track-1;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1830; grid-column: track-2;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1830; grid-column: track-3;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1830; grid-column: track-4;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1830; grid-column: track-5;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1830; grid-column: track-6;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1830; grid-column: track-7;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1830; grid-column: track-8;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1830; grid-column: track-9;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1830; grid-column: track-10;"></span>
    
    <span class="time-slot " style="grid-row: time-1845;"><span class="hhmm"></span></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1845; grid-column: track-1;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1845; grid-column: track-2;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1845; grid-column: track-3;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1845; grid-column: track-4;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1845; grid-column: track-5;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1845; grid-column: track-6;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1845; grid-column: track-7;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1845; grid-column: track-8;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1845; grid-column: track-9;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1845; grid-column: track-10;"></span>

    <span class="time-slot " style="grid-row: time-1900;"><span class="hhmm">19:00</span></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1900; grid-column: track-1;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1900; grid-column: track-2;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1900; grid-column: track-3;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1900; grid-column: track-4;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1900; grid-column: track-5;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1900; grid-column: track-6;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1900; grid-column: track-7;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1900; grid-column: track-8;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1900; grid-column: track-9;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1900; grid-column: track-10;"></span>

    <span class="time-slot " style="grid-row: time-1915;"><span class="hhmm"></span></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1915; grid-column: track-1;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1915; grid-column: track-2;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1915; grid-column: track-3;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1915; grid-column: track-4;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1915; grid-column: track-5;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1915; grid-column: track-6;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1915; grid-column: track-7;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1915; grid-column: track-8;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1915; grid-column: track-9;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1915; grid-column: track-10;"></span>

    <span class="time-slot " style="grid-row: time-1930;"><span class="hhmm">19:30</span></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1930; grid-column: track-1;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1930; grid-column: track-2;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1930; grid-column: track-3;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1930; grid-column: track-4;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1930; grid-column: track-5;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1930; grid-column: track-6;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1930; grid-column: track-7;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1930; grid-column: track-8;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1930; grid-column: track-9;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1930; grid-column: track-10;"></span>

    <span class="time-slot " style="grid-row: time-1945;"><span class="hhmm"></span></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1945; grid-column: track-1;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1945; grid-column: track-2;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1945; grid-column: track-3;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1945; grid-column: track-4;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1945; grid-column: track-5;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1945; grid-column: track-6;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1945; grid-column: track-7;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1945; grid-column: track-8;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1945; grid-column: track-9;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-1945; grid-column: track-10;"></span>

    <span class="time-slot " style="grid-row: time-2000;"><span class="hhmm">20:00</span></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-2000; grid-column: track-1;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-2000; grid-column: track-2;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-2000; grid-column: track-3;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-2000; grid-column: track-4;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-2000; grid-column: track-5;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-2000; grid-column: track-6;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-2000; grid-column: track-7;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-2000; grid-column: track-8;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-2000; grid-column: track-9;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-2000; grid-column: track-10;"></span>

    <span class="time-slot " style="grid-row: time-2015;"><span class="hhmm"></span></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-2015; grid-column: track-1;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-2015; grid-column: track-2;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-2015; grid-column: track-3;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-2015; grid-column: track-4;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-2015; grid-column: track-5;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-2015; grid-column: track-6;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-2015; grid-column: track-7;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-2015; grid-column: track-8;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-2015; grid-column: track-9;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-2015; grid-column: track-10;"></span>

    <span class="time-slot " style="grid-row: time-2030;"><span class="hhmm">20:30</span></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-2030; grid-column: track-1;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-2030; grid-column: track-2;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-2030; grid-column: track-3;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-2030; grid-column: track-4;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-2030; grid-column: track-5;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-2030; grid-column: track-6;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-2030; grid-column: track-7;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-2030; grid-column: track-8;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-2030; grid-column: track-9;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-2030; grid-column: track-10;"></span>

    <span class="time-slot " style="grid-row: time-2045;"><span class="hhmm"></span></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-2045; grid-column: track-1;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-2045; grid-column: track-2;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-2045; grid-column: track-3;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-2045; grid-column: track-4;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-2045; grid-column: track-5;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-2045; grid-column: track-6;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-2045; grid-column: track-7;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-2045; grid-column: track-8;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-2045; grid-column: track-9;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-2045; grid-column: track-10;"></span>

    <span class="time-slot " style="grid-row: time-2100;"><span class="hhmm">21:00</span></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-2100; grid-column: track-1;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-2100; grid-column: track-2;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-2100; grid-column: track-3;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-2100; grid-column: track-4;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-2100; grid-column: track-5;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-2100; grid-column: track-6;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-2100; grid-column: track-7;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-2100; grid-column: track-8;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-2100; grid-column: track-9;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-2100; grid-column: track-10;"></span>

    <span class="time-slot " style="grid-row: time-2115;"><span class="hhmm"></span></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-2115; grid-column: track-1;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-2115; grid-column: track-2;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-2115; grid-column: track-3;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-2115; grid-column: track-4;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-2115; grid-column: track-5;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-2115; grid-column: track-6;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-2115; grid-column: track-7;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-2115; grid-column: track-8;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-2115; grid-column: track-9;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-2115; grid-column: track-10;"></span>

    <span class="time-slot " style="grid-row: time-2130;"><span class="hhmm">21:30</span></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-2130; grid-column: track-1;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-2130; grid-column: track-2;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-2130; grid-column: track-3;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-2130; grid-column: track-4;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-2130; grid-column: track-5;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-2130; grid-column: track-6;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-2130; grid-column: track-7;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-2130; grid-column: track-8;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-2130; grid-column: track-9;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-2130; grid-column: track-10;"></span>

    <span class="time-slot " style="grid-row: time-2145;"><span class="hhmm"></span></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-2145; grid-column: track-1;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-2145; grid-column: track-2;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-2145; grid-column: track-3;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-2145; grid-column: track-4;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-2145; grid-column: track-5;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-2145; grid-column: track-6;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-2145; grid-column: track-7;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-2145; grid-column: track-8;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-2145; grid-column: track-9;"></span>
    <span class="time-line" aria-hidden="true" style="grid-row: time-2145; grid-column: track-10;"></span>
  
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
  *, ::after, ::before {
    box-sizing: border-box;
  }
  .schedule {
    /* width:100%; */
    max-width: 1290px;
    margin: 0 auto;
    background:#fff;
    color:#444444;
    display: grid;
    overflow-x: scroll;
    grid-gap: 1px;
    grid-column-gap: 10px;
    font-size: 13px;
    border: 1px solid #dee2e6;
    grid-template-rows:
      [tracks] auto 
      [tracks2] auto 
      [tracks3] auto 
      [time-0715] 20px 
      [time-0730] 20px 
      [time-0745] 20px 
      [time-0800] 20px 
      [time-0815] 20px 
      [time-0830] 20px 
      [time-0845] 20px 
      [time-0900] 20px 
      [time-0915] 20px 
      [time-0930] 20px 
      [time-0945] 20px 
      [time-1000] 20px 
      [time-1015] 20px 
      [time-1030] 20px 
      [time-1045] 20px 
      [time-1100] 20px 
      [time-1115] 20px 
      [time-1130] 20px 
      [time-1145] 20px 
      [time-1200] 20px 
      [time-1215] 20px 
      [time-1225] 20px
      [time-1230] 20px 
      [time-1245] 20px 
      [time-1300] 20px 
      [time-1315] 20px 
      [time-1330] 20px 
      [time-1345] 20px 
      [time-1400] 20px 
      [time-1415] 20px 
      [time-1430] 20px 
      [time-1445] 20px 
      [time-1500] 20px 
      [time-1515] 20px 
      [time-1530] 20px 
      [time-1545] 20px 
      [time-1600] 20px 
      [time-1615] 20px 
      [time-1630] 20px 
      [time-1645] 20px 
      [time-1700] 20px 
      [time-1715] 20px 
      [time-1730] 20px 
      [time-1745] 20px 
      [time-1800] 20px 
      [time-1815] 20px 
      [time-1830] 20px 
      [time-1845] 20px 
      [time-1900] 20px 
      [time-1915] 20px 
      [time-1930] 20px 
      [time-1945] 20px 
      [time-2000] 20px 
      [time-2015] 20px 
      [time-2030] 20px 
      [time-2045] 20px 
      [time-2100] 20px 
      [time-2115] 20px 
      [time-2130] 20px
      [time-2145] 20px;

      /* Note 1:
      Use 24hr time for gridline names for simplicity

      Note 2: Use "auto" instead of "1fr" for a more compact schedule where height of a slot is not proportional to the session length. Implementing a "compact" shortcode attribute might make sense for this!
      Try 0.5fr for more compact equal rows. I don't quite understand how that works :)
      */
    
    grid-template-columns:
    [times] 0.5fr
    [track-1-start] 1fr
    [track-1-end track-2-start] 1fr
    [track-2-end track-3-start] 1fr
    [track-3-end track-4-start] 1fr
    [track-4-end track-5-start] 1fr
    [track-5-end track-6-start] 1fr
    [track-6-end track-7-start] 1fr
    [track-7-end track-8-start] 1fr
    [track-8-end track-9-start] 1fr
    [track-9-end track-10-start] 1fr
    [track-10-end times];
  }

  .hhmm {
    position:absolute;
    margin-top:-9px;
    margin-right:5px;
    width:55px;
    right:10px;
    background-color:#f5f5f5;
    z-index: 2;
    font-weight:500;
    color:#000;
  }
  .time-slot {
    grid-column: times;
    outline: 1px solid #bbb;
    text-align:center;
    font-size: 13px;
    background-color:#f5f5f5;
    position: sticky;
    left: 0;
    z-index: 1;
  }

  .time-line {
    padding:5px;
    font-size: 14px;
    outline: 1px solid #ddd;
    outline-style: dotted;  
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .track-slot {
    display: block;
    white-space: nowrap;
    top: 0;
    text-align:center;
    padding:10px;
    outline: 1px solid #ccc;
    z-index: 1;
    font-weight:bold;
    background-color: rgba(241, 238, 238, 0.9);
  }

  .track-slot:nth-child(1), .track-slot:nth-child(5), .track-slot:nth-child(13) {
    position: sticky;
    left: 0;
    z-index: 2;
  }

  /*************************
  * VISUAL STYLES
  * Design-y stuff ot particularly important to the demo
  *************************/


  .session {
    padding:3px;
    font-size: 12px;
    outline: 1px solid #948d8d;
    cursor:pointer;
    position:relative;
    background: #bdd7ee;
  }
  .session:hover{
    outline: 1px solid #607D8B;
    opacity: 0.7;
    color:#000;
  }

  .session .badge {
    position:absolute;
    font-size:12px;
    background-color: #6c757d;
    color: #fff;
    padding: 0 3px;
    border-radius: 2px;
  }
  .session-title{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width:100%;
    height:100%;
    padding-top:0px;
    line-height:140%;
    flex-direction:column;
  }

  .session-break{
    font-weight: 600;
    margin-top: 0px;
    width: 100%;
    height: 100%;
    line-height: 140%;
    align-content: center;
    flex-direction: column;
    justify-content: center;
  }

  .session-title .sessionname {
    font-size:14px;
    font-weight:600;
    line-height:140%;
  }

  .session-title .sessiontitle {
    font-weight:300;
    font-style:oblique;
    line-height:120%;
    margin-top:3px;
  }

  .session-time,
  .session-track,
  .session-presenter {
    display: block;
  }

</style>
