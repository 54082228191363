<template>
  <section>
    <div class="banner">
      <div class="banner-text">
        <div class="container">
          <h2 class="banner-title">PROGRAMS</h2>
          <h2 class="banner-sub-title">Program at a Glance</h2>
        </div>
      </div>
    </div>
    <section class="main-container">
      <div class="sub-title">Program at a glance</div>
      <div class="row">
        <div class="lt">*The program is tentative and subject to change.</div>
        <div class="rt">
          <button class="btn" >
            <i class="fa fa-print" aria-hidden="true"></i>
            <span id="print-text">{{ dataStatus }}</span>
          </button>
        </div>
      </div>
      <div class="data-bar">
        <div class="data-item" :class="{'active':item===dataStatus}" v-for="(item,index) in dataList" :key="index" @click="dataStatus=item">
          {{ item }}
        </div>
        <div class="data-item-mb" :class="{'active':item===dataStatus}" v-for="(item,index) in dataList" :key="'0'+index" @click="dataStatus=item">
          {{ `Day${index}` }}
        </div>
      </div>
      <Calendar></Calendar>
    </section>
  </section>
</template>

<script>
import Calendar from './conponents/Calendar.vue'
import {getAction} from "@/utils/request";

export default {
  name:'calendar',
   data() {
    return {
      dataList: ['Day 1, May23(Thu)','Day 2, May24(Fri)','Day 3, May25(Sat)','Day 4, May26(Sun)'],
      dataStatus:'Day 1, May23(Thu)',
    }
  },
  components:{
    Calendar
  },
  created(){
    this.initAgendaInfo()
  },
  methods: {
    async initAgendaInfo(){
      const res = await getAction("/api/sysprogram/getAgendaCompleteInfoByAgendaId?agendaId=1387140317249536")
      console.log(res)
    }
  }
}
</script>


<style lang="less" scoped>
.banner {
  position: relative;
  min-height: 300px;
  color: #fff;
  background-position: 50% 50%;
  background-size: cover;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.1) ), url('../../assets/img/about/banner.png');
  .banner-text {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    z-index: 1;
    transform: translateY(-50%);
    .container {
      min-height: 200px;
      max-width: 1320px;
      margin: 0 auto;
      text-rendering: optimizeLegibility;
      -webkit-font-smoothing: antialiased !important;
      .banner-title {
        color: #e8bd55;
        text-transform: uppercase;
        font-size: 58px;
        font-weight: 900;
      }
      .banner-sub-title{
        color: #fff;
        font-size: 48px;
        font-weight: 900;
      }
      @media (max-width:1320px) {
        padding: 0 15px;
      }
      @media (max-width:768px) {
        .banner-title {
          font-size: 48px;
        }
        .banner-sub-title {
          font-size: 38px;
        }
      }
    }
  }
}
.main-container {
  max-width: 1320px;
  margin: 0 auto;
  padding:0 15px 100px;
  .sub-title {
    font-weight: 900;
    font-size: 27px;
    line-height: normal;
    margin: 30px 0px 20px 0px;
    padding-bottom: 10px;
    border-bottom: solid 1px #ddd;
  }
  .row {
    font-family: "PublicSans Regular";
    display: flex;
    font-size: 16px;
    .lt {
      color: #dc3545;
      flex: 1;
      padding: 6px 0;
    }
    .rt {
      color: #fff;
      background-color: #17a2b8;
      padding: 6px 12px;
      text-align: center;
      width: 190px;
      border-radius: 4px;
    }
    @media (max-width: 992px) {
      display: block;
      .rt {
        width: 100%;
      }
    }
  }
  .data-bar {
    display: flex;
    height: 64px;
    line-height: 64px;
    border: 1px solid #dee2e6;
    background-color: #f8f9fa;
    border-radius: 32px;
    margin: 15px 0;
    .data-item {
      font-size: 21px;
      flex: 1;
      text-align: center;
      color: #1175e7;
      border-radius: 32px;
      transition: all .4s;
      cursor: pointer;
    }
    .data-item-mb {
      display: none;
      font-size: 21px;
      flex: 1;
      text-align: center;
      color: #1175e7;
      border-radius: 32px;
      transition: all .4s;
      cursor: pointer;
    }
    .active {
      color: #fff;
      background:linear-gradient(73deg, rgba(63,136,163,1) 30%, rgba(88,46,104,1) 100%);
    }
    @media (max-width: 992px) {
      .data-item {
        display: none;
      }
      .data-item-mb {
        display: block;
      }

    }
  }
}
</style>
